import styled from '@emotion/styled';
import { Button } from '../Button';

export const StyledDropdownContainer = styled.div(() => ({
    position: 'relative',
    display: 'inline-block',
}));

export const StyledDropdownTrigger = styled(Button)<{ isOpen: boolean }>(({ theme, isOpen }) => ({
    height: theme.spaces[6],
    fontFamily: theme.fonts.body,
    fontSize: theme.fontSizes.sm,
    padding: `0 ${theme.spaces[2]} 2px`, // Padding Bottom 2px because of border 2px except in bottom, causes a desalignment with other items
    border: `2px solid ${isOpen ? theme.colors.black : 'transparent'}`,
    borderBottom: `none`,
    ['&:focus-visible']: {
        outline: `2px solid ${theme.colors.grey20}`,
    },
}));

export const StyledDropdownTitle = styled.label(({ theme }) => ({
    marginRight: theme.spaces[2],
    fontFamily: theme.fonts.body,
    fontSize: theme.fontSizes.sm,
    cursor: 'pointer',
}));

export const StyledDropdownList = styled.ul(({ theme }) => ({
    zIndex: theme.zIndexes[1],
    position: 'absolute',
    backgroundColor: theme.colors.white,
    border: `2px solid ${theme.colors.black}`,
    minWidth: '100%',
}));

export const StyledListItem = styled.li(({ theme }) => ({
    ['&:hover']: {
        backgroundColor: theme.colors.grey20,
    },
    ['&:focus']: {
        outline: 'none',
        backgroundColor: theme.colors.grey20,
    },
    cursor: 'pointer',
}));

export const StyledListOption = styled.span<{ isSelected: boolean }>(({ theme, isSelected }) => ({
    fontFamily: theme.fonts.body,
    fontSize: theme.fontSizes.sm,
    fontWeight: isSelected ? theme.fontWeights.bold : theme.fontWeights.regular,
    padding: `${theme.spaces[3]} ${theme.spaces[2]}`,
    minWidth: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ['&:focus']: {
        outline: 'none',
        backgroundColor: theme.colors.grey20,
    },
}));
