import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export const Wrapper = styled.div<{ marginBottom?: number; marginTop?: number }>(
    () => ({
        position: 'relative',
        width: 'auto',
    }),

    ifProp('marginBottom', ({ marginBottom }) => ({
        marginBottom: `${marginBottom}px`,

        ul: {
            position: 'unset',
        },
    })),

    ifProp('marginTop', ({ marginTop }) => ({
        marginTop: `${marginTop}px`,
    }))
);

type StyledButtonProps = {
    isOpen: boolean;
};

export const StyledButton = styled.button<StyledButtonProps>(
    ({ theme }) => ({
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
        border: `1px solid ${theme.colors.black}`,
        cursor: 'pointer',
        display: 'flex',
        fontSize: theme.fontSizes.sm,
        height: theme.spaces[6],
        lineHeight: theme.lineHeights.base,
        padding: `0px ${theme.spaces[2]}`,
        width: '100%',
        svg: {
            width: 25,
            height: 25,
        },

        label: {
            cursor: 'pointer',
        },

        ':focus': {
            outline: 'none',
        },
    }),

    ifProp('isOpen', ({ theme }) => ({
        backgroundColor: theme.colors.black,
        color: theme.colors.white,

        svg: {
            transform: 'rotate(180deg)',
        },
    }))
);

export const StyledOpenContent = styled.ul<{ isOpen?: boolean }>(
    ({ theme }) => ({
        zIndex: theme.zIndexes[1],
        position: 'absolute',
        minWidth: '100%',
        minHeight: theme.spaces[4],
        backgroundColor: theme.colors.white,
        padding: theme.spaces[2],
    }),

    ifProp('isOpen', ({ theme }) => ({ border: `1px solid ${theme.colors.black}` }))
);
