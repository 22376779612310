import React from 'react';
import { Dropdown } from '~/shared/components';

import { StyledSortContainer, StyledLabel } from './styled';
import { useProductsStore } from '../hooks';
import { deepEqual } from '~/shared/utils';

export const SortDropdown = () => {
    const { setSelectedSort, selectedSort, sortOptions } = useProductsStore();

    if (!sortOptions.length) {
        return null;
    }

    const setSort = (index: number) => {
        const selectedOption = sortOptions[index];
        if (selectedOption && !deepEqual(selectedOption, selectedSort)) {
            setSelectedSort(selectedOption);
        }
    };

    const selectedSortIndex = sortOptions.findIndex((item) => deepEqual(selectedSort, item));

    return (
        <StyledSortContainer>
            <StyledLabel>Sort:</StyledLabel>
            <Dropdown
                items={sortOptions.map((item, index) => {
                    return { label: item.label, value: index };
                })}
                value={selectedSortIndex === -1 ? 0 : selectedSortIndex}
                name="sort"
                onChange={(index) => {
                    if (typeof index == 'number') {
                        setSort(index);
                    }
                }}
            />
        </StyledSortContainer>
    );
};
