import React, { useState, useRef } from 'react';
import ChevronDown from '$icons/chevron/down.svg';
import { StyledOpenContent, StyledButton, Wrapper } from './styled';
import { useClickAway } from 'react-use';

type EmptyDropdownProps = {
    children: React.ReactNode;
    label: string;
};

export const EmptyDropdown = ({ label, children }: EmptyDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useClickAway(
        dropdownRef,
        (e: MouseEvent) => {
            if (dropdownRef?.current?.contains(e.target as HTMLElement)) return;
            setIsOpen(false);
        },
        ['click']
    );

    return (
        <Wrapper ref={dropdownRef}>
            <StyledButton type="button" isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <span>{label}</span>
                <ChevronDown aria-hidden="true" />
            </StyledButton>

            <StyledOpenContent isOpen={isOpen}>{isOpen ? children : null}</StyledOpenContent>
        </Wrapper>
    );
};
