/* eslint-disable @typescript-eslint/no-explicit-any */
export * from './utils';
/**
 * omit function returns a copy of the original object without the keys provied in KeyToOmit
 *
 *  Params
 *
 *  obj - Object
 *  Object from where to remove key
 *
 *  keysToOmit - Array
 *  List of keys to omit from original object
 */
export const omit = (obj: any, keysToOmit: string[]) => {
    return Object.keys(obj).reduce((result: any, key: any) => {
        if (!keysToOmit.includes(key)) {
            result[key] = obj[key];
        }
        return result;
    }, {});
};

/**
 * isObject function validates if value passed has type object and its not null
 *
 *  Params
 *
 *  obj - Object
 *  value to validate type
 */
export const isObject = (obj: any) => {
    return typeof obj == 'object' && obj != null;
};

/**
 * deepEqual function compares two values and calls itself if keys have comples type
 *
 *  Params
 *
 *  x - Object
 *  value to compare
 *
 *  y - Object
 *  value to compare
 */
export const deepEqual = (x: any, y: any) => {
    if (x === y) {
        return true;
    } else if (isObject(x) && isObject(y)) {
        // Check if has the same number of keys
        if (Object.keys(x).length != Object.keys(y).length) {
            return false;
        }

        for (const prop in x) {
            // foreach key in both objt compare
            // if key not present in one object return false (different object)
            if (
                !Object.prototype.hasOwnProperty.call(y, prop) ||
                (Object.prototype.hasOwnProperty.call(y, prop) && !deepEqual(x[prop], y[prop]))
            ) {
                return false;
            }
        }

        return true;
    } else return false;
};
