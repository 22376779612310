import styled from '@emotion/styled';

export const StyledSortContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
}));

export const StyledLabel = styled.div(({ theme }) => ({
    marginRight: theme.spaces[2],
}));
