import React, { useState, useMemo, useCallback, useRef, KeyboardEvent } from 'react';
import {
    StyledDropdownContainer,
    StyledDropdownTrigger,
    StyledDropdownTitle,
    StyledDropdownList,
    StyledListItem,
    StyledListOption,
} from './styled';
import { useClickAway } from 'react-use';
import ChevronDown from '$icons/chevron/down.svg';
import ChevronUp from '$icons/chevron/up.svg';
import { DropdownItem } from '../MultipleSelector';
import { deepEqual } from '~/shared/utils';

type ValueType = string | number | unknown;

export interface DropdownProps {
    items: DropdownItem[];
    value: ValueType;
    onChange: (value: ValueType) => void;
    name: string;
}

export const Dropdown = ({ items, value, onChange, name }: DropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const currentLabel = useMemo(() => {
        return items?.find((item) => item.value === value)?.label || '';
    }, [items, value]);

    const setValue = useCallback(
        (newValue: ValueType) => {
            onChange(newValue);
        },
        [onChange]
    );

    useClickAway(
        dropdownRef,
        (e: MouseEvent) => {
            if (dropdownRef?.current?.contains(e.target as HTMLElement)) return;
            setIsOpen(false);
        },
        ['click']
    );

    if (!items.length) return null;

    // Event handler for keydowns
    const handleKeyDown = (e: KeyboardEvent, selectedValue?: ValueType) => {
        const selectedIndex = items.findIndex((item) => deepEqual(item.value, value));

        switch (e.key) {
            case 'Tab':
                setIsOpen(false);
                break;
            case 'Escape':
                e.preventDefault();
                setIsOpen(false);
                break;
            case 'Home':
                if (dropdownRef?.current !== undefined) {
                    (dropdownRef.current?.querySelector(
                        'li:first-of-type span'
                    ) as HTMLElement)?.focus();
                }
                break;
            case 'End':
                if (dropdownRef?.current !== undefined) {
                    (dropdownRef.current?.querySelector(
                        'li:last-of-type span'
                    ) as HTMLElement)?.focus();
                }
                break;
            case 'ArrowDown':
                if (!isOpen) {
                    setIsOpen(true);
                }
                if (selectedIndex == -1) {
                    setValue(items[0].value);
                } else if (items.length > selectedIndex + 1) {
                    setValue(items[selectedIndex + 1].value);
                }
                break;
            case 'ArrowUp':
                if (!isOpen) {
                    setIsOpen(true);
                }
                if (selectedIndex == -1) {
                    setValue(items[0].value);
                } else if (0 <= selectedIndex - 1) {
                    setValue(items[selectedIndex - 1].value);
                }
                break;
            case 'Enter':
                e.preventDefault();
                if (selectedValue) {
                    setValue(selectedValue);
                }
                setIsOpen(false);
                break;
            default:
                break;
        }
    };

    return (
        <StyledDropdownContainer ref={dropdownRef}>
            <StyledDropdownTrigger
                isOpen={isOpen}
                aria-haspopup="listbox"
                aria-expanded={isOpen}
                aria-labelledby={name}
                onClick={() => setIsOpen(!isOpen)}
                onKeyDown={handleKeyDown}
            >
                <StyledDropdownTitle id={name}>{currentLabel}</StyledDropdownTitle>
                {!isOpen ? <ChevronDown aria-hidden="true" /> : <ChevronUp aria-hidden="true" />}
            </StyledDropdownTrigger>
            {isOpen ? (
                <StyledDropdownList
                    role="listbox"
                    aria-activedescendant={`${name}-${value}`}
                    aria-labelledby={name}
                    tabIndex={-1}
                >
                    {items.map((item) => (
                        <StyledListItem
                            id={item.value.toString()}
                            role="option"
                            aria-selected={value === item.value}
                            key={item.value.toString()}
                            aria-labelledby={item.value.toString()}
                            onClick={() => {
                                setValue(item.value);
                                setIsOpen(false);
                            }}
                        >
                            <StyledListOption
                                isSelected={value === item.value}
                                id={`${name}-${item.value}`}
                                aria-label={item.value.toString()}
                                onKeyDown={(e) => handleKeyDown(e, item.value)}
                                tabIndex={0}
                            >
                                {item.label}
                            </StyledListOption>
                        </StyledListItem>
                    ))}
                </StyledDropdownList>
            ) : null}
        </StyledDropdownContainer>
    );
};
